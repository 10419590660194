.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin: 0;
  overflow-x: hidden;
}

.textContainer{
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 50px; */
}
.section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section:first-child{
  
  font-size: 39px;
}
.section h2 {
  letter-spacing: 0.125em;
  font-size: 28px;
}

.sectionTwo {
  display: block;
  box-sizing: border-box;
  padding: 120px 50px 120px 50px;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
}

.divLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
}
.divLeft h2 {
  font-size: 28.5px;
  letter-spacing: 1.2px;
  font-weight: 400;
}
.divLeft p {
  padding: 0.3em 0.3em 0.2em;
}
.divRight {
  display: flex;
  justify-content: center;
  width: 50%;
  /* height: 100%; */
  background-color: black;
}

.img {
  /* height: 100%; */
  width: 100%;
  background-image: url("https://i.pinimg.com/originals/29/59/84/29598458b6f9c47c0a3dfe188ba26e4b.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.buttons {
  display: flex;
  width: fit-content;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
}
.footerButton {
  width: 200px;
  border-style: none;
  border-radius: 45px;
  background-color: transparent;

  border: 1px solid white;
  color: white;
  font-weight: bolder;
  font-size: 18px;
  cursor: pointer;
  padding: 25px;
}
.footerButton:hover {
  color: gray;
  -webkit-transition: color 1000ms linear;
  -ms-transition: color 1000ms linear;
  transition: color 1000ms linear;
  border: 1px solid gray;
  -webkit-transition: border color 1000ms linear;
  -ms-transition: border color 1000ms linear;
  transition: border color 1000ms linear;
}
.or {
  padding: 50px;
  font-size: 20px;
}
.sectionCompany{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 50px;
  background-color: #0F0F49;
  color: white;
}

.sectionCompany h2{
  letter-spacing: 20px;
  font-size: 46px;
}
.sectionCompany p{
  font-weight:200;
}
.company{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-right: 4%;
  height:100vh;
}
company p{
  box-sizing: content-box;
}

.companyImg{
  margin-left: 10px;
  background-image: url('https://media.istockphoto.com/vectors/line-icon-vector-id623921056');
  height: 100%;
  width: 50%;
}
.industries a{
  text-decoration: none;
  color: black;
  padding: 10px;
}
.industries :hover{
  cursor: pointer;
  background-color: #0F0F49;
  color: white;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
}
@media only screen and (max-width: 960px){
  .section{
    height: 100vh;
    width: 100vw;
    background-size: contain;
    display: flex;
    flex-direction: column;
    padding: 50px;
    background-size: cover;
  }
  .section h2{
    font-size: 20px;
  }
  .buttons{
    display: flex;
    flex-direction: column;
  }
  .sectionCompany{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .companyImg{
    display:none;
  }
  .sectionCompany h2{
    font-size: 26px;
    text-align: center;
  }
  .industries{
    text-align: center;
  }
  /* .sectionTwo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */
  .divLeft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .divRight{
    display: none;
  }

}